import { useEffect, useState } from "react";
import { Player } from "../types";
import "./PlayerPresentation.css";

type Props = {
  player: Player;
  delay?: number;
};

function calculateAge(date: Date) {
  var diff_ms = Date.now() - date.getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

const PlayerPresentation: React.FC<Props> = ({ player, delay = 0 }) => {
  const { number, name, pictureUrl } = player;
  const [opacity, setOpacity] = useState(0);
  const [x, setX] = useState(-100);

  const names = (name || "").split(" ");
  const shortName = `${names[0]} ${names[names.length - 1]}`;

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
      setX(0);
    }, delay);
  }, []);

  const age = calculateAge(new Date(player.birthday));

  return (
    <div className="player-presentation">
      <div
        className="player-presentation-picture-container"
        style={{ opacity, transform: `translateX(${x})` }}
      >
        <div
          className="player-presentation-picture"
          style={{ backgroundImage: `url(${pictureUrl})` }}
        ></div>
      </div>

      <div
        className="player-presentation-data"
        style={{ opacity, transform: `translateX(${x})` }}
      >
        <div className="player-presentation-name">{shortName}</div>
        <div className="player-presentation-age">{age} anos</div>

        <div
          className="player-presentation-number"
          style={{
            backgroundImage: "url(/images/adcvnm_shirt.png)",
          }}
        >
          {number}
        </div>
      </div>
    </div>
  );
};

export default PlayerPresentation;
