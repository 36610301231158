import React from "react";

import "./App.css";

import { Team } from "./types";
import TeamBlock from "./components/TeamBlock";

import useTeamData from "./hooks/useTeamData";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TeamPresentation from "./components/TeamPresentation";

function App() {
  const adcvnm = useTeamData("adcvnm");

  const sosense: Team = {
    name: "Sosense",
    logoUrl: "https://api.sofascore.app/api/v1/team/401298/image",
    players: [],
  };

  return (
    <div className="App">
      { adcvnm ? <TeamPresentation team={adcvnm}></TeamPresentation> : <></> }
    </div>
  );
}

export default App;
