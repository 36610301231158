import { useEffect, useState } from "react";
import { Player, Team } from "../types";
import PlayerPresentation from "./PlayerPresentation";
import "./TeamPresentation.css";
import CoachPresentation from "./CoachPresentation";

const DELAY = 5000;

type Props = { team: Team };

enum AgentType {
  STARTING_11,
  SUBSTITUTES,
  COACH,
}

const TeamPresentation: React.FC<Props> = ({ team }) => {
  const { name, players, mainCoach, logoUrl } = team;

  const [agentType, setAgentType] = useState(AgentType.STARTING_11);

  const starting = players.filter((player) => player.isOnStarting);
  const substitutes = players.filter((player) => !player.isOnStarting);

  const showingPlayers =
    agentType === AgentType.STARTING_11 ? starting : substitutes;

  const [currentPlayer, setCurrentPlayer] = useState(0);

  useEffect(() => {
    if (agentType !== AgentType.STARTING_11) return;

    if (currentPlayer < starting.length) {
      setTimeout(() => setCurrentPlayer(currentPlayer + 1), DELAY);
    } else {
      setCurrentPlayer(0);
      setAgentType(AgentType.SUBSTITUTES);
    }
  }, [currentPlayer]);

  useEffect(() => {
    if (agentType !== AgentType.SUBSTITUTES) return;

    if (currentPlayer < substitutes.length) {
      setTimeout(() => setCurrentPlayer(currentPlayer + 1), DELAY);
    } else {
      setAgentType(AgentType.COACH);
    }
  }, [currentPlayer]);

  /*
  useEffect(() => {
    if (agentType !== AgentType.SUBSTITUTES) return;
    
    if (currentPlayer < substitutes.length - 1) {
      setTimeout(() => setCurrentPlayer(currentPlayer + 1), DELAY);
    }
  }, [currentPlayer]);
  */

  const currentAgent =
    agentType === AgentType.COACH ? mainCoach : showingPlayers[currentPlayer];

  return (
    <div className="team-presentation">
      <div className="team-presentation-team-data">
        <div className="team-presentation-logo">
          <img src={team.logoUrl} />
        </div>
        <div className="team-presentation-name">{team.name}</div>

        <div className="team-presentation-group">
          {agentType === AgentType.STARTING_11
            ? "11 inicial"
            : agentType === AgentType.SUBSTITUTES
            ? "Suplentes"
            : "Treinador"}
        </div>
      </div>

      {currentAgent ? (
        <div className="team-presentation-players-presentation">
          {agentType !== AgentType.COACH ? (
            <PlayerPresentation
              key={currentAgent.name}
              player={currentAgent as Player}
              delay={500}
            ></PlayerPresentation>
          ) : mainCoach ? (
            <CoachPresentation agent={mainCoach}></CoachPresentation>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TeamPresentation;
