import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Agent, Team } from "../types";

const transformTeam = (rawTeam: any) => {
  const team: Team = {
    name: rawTeam.name,
    logoUrl: rawTeam.logoUrl,
    mainCoach: rawTeam.agents
      .filter((player: any) => player.role.id === "3")
      .map((player: any) => ({
        name: player.name,
        pictureUrl: player.picture_url,
        birthday: player.custom_fields.birthday,
        isOnStarting: player.custom_fields.starting,
      }))[0],
    players: rawTeam.agents
      .filter((player: any) => player.custom_fields.called)
      .map((player: any) => ({
        name: player.name,
        pictureUrl: player.picture_url,
        birthday: player.custom_fields.birthday,
        number: player.custom_fields.number,
        isOnStarting: player.custom_fields.starting,
      })),
  };

  console.log(rawTeam);
  console.log(team);

  return team;
};

const fetchTeam = (team: string): Promise<any> =>
  axios
    // .get(`https://api.adcvnm.pt/sport-activity/teams/${teamId}`)
    .get(`/data/${team}_3.json`)
    .then((response) => response.data);

const useTeamData = (teamName?: string) => {
  const { data } = useQuery({
    queryKey: ["team"],
    queryFn: () =>
      teamName
        ? fetchTeam(teamName).then((data) => transformTeam(data))
        : Promise.resolve(null),
  });

  return data;
};

export default useTeamData;
