import { useEffect, useState } from "react";
import { Agent, Player } from "../types";
import "./CoachPresentation.css";

type Props = {
  agent: Agent;
  delay?: number;
};

function calculateAge(date: Date) {
  var diff_ms = Date.now() - date.getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

const CoachPresentation: React.FC<Props> = ({ agent, delay = 0 }) => {
  const { name, pictureUrl } = agent;
  const [opacity, setOpacity] = useState(0);
  const [x, setX] = useState(-100);

  const names = (name || "").split(" ");
  const shortName = `${names[0]} ${names[names.length - 1]}`;

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
      setX(0);
    }, delay);
  }, []);

  const age = calculateAge(new Date(agent.birthday));

  return (
    <div className="coach-presentation">
      <div
        className="coach-presentation-picture-container"
        style={{ opacity, transform: `translateX(${x})` }}
      >
        <div
          className="coach-presentation-picture"
          style={{ backgroundImage: `url(${pictureUrl})` }}
        ></div>
      </div>

      <div
        className="coach-presentation-data"
        style={{ opacity, transform: `translateX(${x})` }}
      >
        <div className="coach-presentation-name">{shortName}</div>
      </div>
    </div>
  );
};

export default CoachPresentation;
